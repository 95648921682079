//import historyProvider from './historyProvider'
//import historyProvider from './sendDataProvider'
import historyProvider from './testProvider.js'; // 과거 데이터 관리

import stream from './stream'

const supportedResolutions = ["1", "3", "5", "15", "30", "60", "120", "240", "D"]

const config = {
    supported_resolutions: supportedResolutions,
	exchanges: [
        { value: 'Bitfinex', name: 'Bitfinex', desc: 'Bitfinex'},
        { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange'},
    ]
}; 

// Socket.IO 클라이언트 설정
const socket = io(`https://stream.coinpass.co.kr`, {
	query: { data: market },  // 설정된 마켓 정보 전송
	cors: { origin: '*' }
  });

export default {
	onReady: cb => {
	console.log('=====onReady running')	
		setTimeout(() => cb(config), 0)
		
	},
	searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
		console.log('====Search Symbols running')
	},
	resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
		// expects a symbolInfo object in response
		console.log('======resolveSymbol running')
		// console.log('resolveSymbol:',{symbolName})
		//var split_data = symbolName.split(/[:/]/)
		var split_data = symbolName.split(/(\w{3})(USD|USDT)/);
		// console.log({split_data})
		var symbol_stub = {
			name: symbolName,
			description: '',
			type: 'crypto',
			session: '24x7',
			timezone : 'Asia/Seoul',
			ticker: symbolName,
			exchange: split_data[0].substring(0, 3),
			minmov: 1,
			pricescale: 100000000,
			has_intraday: true,
			intraday_multipliers: ['1', '60'],
			supported_resolution:  supportedResolutions,
			volume_precision: 8,
			data_status: 'streaming',
		}

		 // 초기 가격을 가져오기 위한 API 호출
		 fetch(`/api/bars?coin=${coin}`)
		 .then(response => response.json())
		 .then(data => {
			 if (data && data.data && data.data.length > 0) {
				 const lastPrice = parseFloat(data.data[data.data.length - 1].close);
				 console.log('Initial price:', lastPrice);
				 
				 // 가격에 따라 pricescale 설정
				 symbol_stub.pricescale = lastPrice <= 1 ? 10000 : 100;
				 console.log('Setting pricescale:', symbol_stub.pricescale);
				 
				 onSymbolResolvedCallback(symbol_stub);
			 } else {
				 // API에서 데이터를 받지 못한 경우 실시간 데이터 사용
				 socket.once('/ticker', (tickerData) => {
					 if (tickerData && tickerData.trade_price) {
						 const price = parseFloat(tickerData.trade_price);
						 console.log('Socket price:', price);
						 
						 symbol_stub.pricescale = price <= 1 ? 10000 : 100;
						 console.log('Setting pricescale from socket:', symbol_stub.pricescale);
					 }
					 onSymbolResolvedCallback(symbol_stub);
					 
					 // 일회성 이벤트 후 연결 종료
					 socket.off('/ticker');
				 });
			 }
		 })
		 .catch(error => {
			 console.error('Failed to fetch initial price:', error);
			 onSymbolResolvedCallback(symbol_stub);
		 });
		
		
		// onResolveErrorCallback('Not feeling it today')

	},
	getBars: function(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
		console.log('=====getBars running')
		// console.log('function args',arguments)
		// console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
		historyProvider.getBars(symbolInfo, resolution, from, to, firstDataRequest)
		.then(bars => {
			if (bars.length) {
				onHistoryCallback(bars, {noData: false})
			} else {
				onHistoryCallback(bars, {noData: true})
			}
		}).catch(err => {
			console.log({err})
			onErrorCallback(err)
		})

	},
	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
		console.log('=====subscribeBars runnning')
		stream.subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback)
	},
	unsubscribeBars: subscriberUID => {
		console.log('=====unsubscribeBars running')
		stream.unsubscribeBars(subscriberUID)
	},
	calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
		//optional
		console.log('=====calculateHistoryDepth running')
		// while optional, this makes sure we request 24 hours of minute data at a time
		// CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
		
		return resolution < 60 ? {resolutionBack: 'D', intervalBack: '1'} : undefined
	},
	getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		//optional
		console.log('=====getMarks running')
	},
	getTimeScaleMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		//optionalcustom_indicators_getter
		console.log('=====getTimeScaleMarks running')
	},
	getServerTime: cb => {
		console.log('=====getServerTime running')
	}
}
