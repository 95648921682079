import * as React from 'react'; 
import './index.css';
import Datafeed from './api/';
import socketClient from 'socket.io-client';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// URL에서 coin 파라미터 추출하는 함수
function getCoinFromURL() {
	const params = new URLSearchParams(window.location.search);
	return params.get('coin') || 'BTC';  // 기본값으로 'BTC'를 사용
}

function convertToPercentage(value) {
	return (value / 100) * 100;
  }
  
  // 부동소수점 문제를 확실하게 처리하는 함수 (소수점 2자리로 고정 후 변환)
  function handleFloatingPoint(value) {
	return parseFloat(value.toFixed(2)); // 소수점 두 자리로 고정 후 숫자로 변환
  }

export class TVChartContainer extends React.PureComponent {

	static defaultProps = {
		interval: '1',
		containerId: 'tv_chart_container',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'jshunk045@gmail.com',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
		timezone: 'Asia/Seoul',
	};

	componentDidMount() {

		// URL에서 coin 파라미터 추출
		const coin = getCoinFromURL(); 
		//const coinSymbols = coin ? `${coin.toUpperCase()}USDT` : 'BTCUSDT';
		
		const symbol = coin ? `${coin.toUpperCase()}USDT` : 'BTCUSDT';

		// 차트 위젯 옵션 초기화
		const widgetOptions = {
			debug: false,
			symbol: symbol,
			datafeed: Datafeed,
			interval: this.props.interval,
			container_id: this.props.containerId,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || 'ko',
			timezone: this.props.timezone || "Asia/Seoul",
			disabled_features: ['header_screenshot','header_saveload','header_fullscreen_button','header_settings','show_trading_notifications_history','header_logarithmic','volume_force_overlay'],
			enabled_features: ['study_templates'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: false,
			autosize: true,
			studies_overrides: this.props.studiesOverrides,
			theme: "dark",
			overrides: {
				
				// 캔들 색상
				"mainSeriesProperties.candleStyle.upColor": "#e15241",
				"mainSeriesProperties.candleStyle.downColor": "#4880ee",
				// 배경 색상
				"paneProperties.background": "#161a25",  // 검정 배경 (다크 테마)
				// 그리드 라인 색상 및 스타일
				"paneProperties.vertGridProperties.color": "#424242", // 수직 그리드 색상
				"paneProperties.horzGridProperties.color": "#424242", // 수평 그리드 색상
				"paneProperties.horzGridProperties.style": 1, // 수직 그리드를 점선으로 설정 (1 = 점선)
				"paneProperties.vertGridProperties.style": 1, // 수평 그리드를 점선으로 설정 (1 = 점선)
				"paneProperties.separatorColor": "#9598a1",

				// 텍스트 색상 (모든 텍스트를 흰색으로 설정)
				"scalesProperties.lineColor": "#D3D3D3",
				"scalesProperties.textColor": "#FFFFFF",  // 축 텍스트 흰색
				"priceScale.textColor": "#FFFFFF",        // 가격 텍스트 흰색
				"timeScale.textColor": "#FFFFFF",         // 시간 텍스트 흰색
				// 배경에서 지표 인자 체크 해제
				// Show indicator last value on the price scale
				"scalesProperties.showSeriesLastValue": true,
				
				// Show indicator labels
				"paneProperties.legendProperties.Arguments": true,  // 지표 인자 숨기기
				"paneProperties.legendProperties.showLegend": true,  // 범례는 표시 유지
				"scalesProperties.showSeriesLastValue": true,  // 마지막 값을 눈금에 표시
				"scalesProperties.showSeriesTitle": true,  // 지표의 이름을 눈금에 표시	
				
			}
		};

		// 차트 초기화
		Datafeed.onReady(() => {
			const widget = (window.tvWidget = new window.TradingView.widget(widgetOptions));
			
			widget.onChartReady(() => {
				console.log('Chart has loaded!');
				// 차트가 로드된 후
				

				// 이동평균선(MA) 추가
				//widget.chart().createStudy('Moving Average', false, false, [7], null, {
				//	"plot.color.0": "#e15241"  // 7일 이동평균선 빨간색
				//});
				//widget.chart().createStudy('Moving Average', false, false, [25], null, {
				//		"plot.color.0": "#ff6600"  // 25일 이동평균선 주황색
				//	});
				//widget.chart().createStudy('Moving Average', false, false, null, null, {
				//	"plot.color.0": "#00ff00",  // 99일 이동평균선 초록색
				//	"height": 400
				//});
				// 차트에 있는 모든 연구에 대한 ID와 이름의 배열을 생성
	
				widget.applyOverrides({
					// 패널 크기 조정
					"paneProperties.height": 500,	
					"paneProperties.legendProperties.showLegend": true,  // 범례를 표시
					"paneProperties.legendProperties.Arguments": false,
					"paneProperties.topMargin": 0,  // 패널 상단 여백
					"paneProperties.bottomMargin": 0,  // 패널 하단 여백
					"showStudyArguments": false,
				});
				


				// 서버에서 데이터 가져오기
				fetch(`/api/bars?coin=${coin}`)
					.then((response) => response.json())
					.then((data) => {
						widget.applyOverrides({
							"mainSeriesProperties.candleStyle.upColor": data.upColor || "#e15241",   // 상승 색상
							"mainSeriesProperties.candleStyle.downColor": data.downColor || "#4880ee",  // 하락 색상
							"paneProperties.background": data.backgroundColor || "#000000",  // 배경 색상
						});
						
					})
					.catch((error) => console.error('차트 데이터 가져오기 오류:', error));
			});
			
			
			
		});
	}
	

	render() {
		return (
			<div
				id={this.props.containerId}
				className={'TVChartContainer'}
				
			/>
		);
	}
}
